import React, { useEffect, useState } from 'react';
import './menu.css';
import { Link } from 'react-router-dom';
import logo from './Zdj/bt-log-pro.png';

function Menu() {
  const [scrolled, setScrolled] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  return (
      <nav
          id="nav"
          className={`${scrolled ? 'scrolled' : ''} ${isMobileMenuOpen ? 'mobile-open' : ''}`}
      >
        <div className="logo-container">
          <img className="logo" src={logo} alt="Logo" />
        </div>
        <div className="menu-icon" onClick={toggleMobileMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
          {[
            { path: '/', label: 'Strona Główna' },
            { path: '/nasze-domki', label: 'Domki 6-8 osobowe' },
            { path: '/apartament', label: 'Domek 2-osobowy' },
            { path: '/gallery-p', label: 'Galeria' },
            { path: '/kontakt', label: 'Kontakt' },
            { path: '/regulamin', label: 'Regulamin' },
          ].map((link, index) => (
              <li key={index} className={!isMobileMenuOpen && window.innerWidth <= 768 ? 'hidden' : ''}>
                <Link to={link.path} onClick={toggleMobileMenu}>
                  {link.label}
                </Link>
              </li>
          ))}
        </ul>
      </nav>
  );
}

export default Menu;
